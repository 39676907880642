import React from "react"

import Layout from "../../layout/layout"
// import Button from "../components/button"
import "../../styles/homestay.css"
import Homestay1 from "../../images/homestay1.webp"
import Homestay2 from "../../images/homestay2.webp"
import Homestay3 from "../../images/homestay3.png"
import Homestay4 from "../../images/homestay4.webp"
import Homestay6 from "../../images/homestay6.webp"
import Homestay7 from "../../images/homestay7.webp"
import Homestay8 from "../../images/homestay8.webp"
const Homestay = () => {
  return (
    <Layout
      title="Homestay"
      description=""
    >
      <div className="layout-container homestay-container">
        <div className="mainimages"><img src={Homestay1}/><img src={Homestay2}/></div>
        <div className="content">
          <p className="p-lg"><strong>Kia Ora!</strong></p>
          <p className="p-lg">Host Families NZ welcomes you to New Zealand! This guide is designed to provide key information and outline the student’s responsibilities to ensure an enjoyable and safe homestay living environment. We will apply on your behalf, or <a href="https://www.hostfamilies.co.nz/students-visitors/students-visitors-2/" target="_blank">you can apply directly with HFNZ here.</a></p>
          <img src={Homestay3} />
          <p className="p-lg">There is a lot of information on this page related to the policies of HFNZ. Please contact us if you have any questions.</p>
          <p className="p-lg">You will sign an agreement with HFNZ to abide by the information below. HFNZ adheres to the Code of Practice.</p>
          <div className="row rowmobilereverse">
            <div className="col-lg-6">
            <h4>1. Homestay Introduction</h4>
          <p className="p-lg">Homestay is a popular form of accommodation provided to students/interns/visitors and it is a great way to settle in a new city, new country, or simply a new environment. In New Zealand, each family or household can host up to 4 students at a time. Most homestay hosts have one or two rooms for the student. However, some homestays have a bigger house where they can accommodate three to four students.</p>
          <p className="p-lg">
          The host family will provide you with a private bedroom, a bed, study desk, chair, lamp and somewhere to put your clothes. Beddings will also be available for you to use. However, you are welcome to bring your own linen.
          </p>
          <p className="p-lg">Personal things such as toiletries and shampoo are the student’s responsibility.</p>
            </div>
            <div className="col-lg-6">
            <img src={Homestay4}/>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
            <iframe width="100%" height="450" src="https://www.youtube.com/embed/QKjv3QluKu4?si=Q7dwc1kGNwpUN1a2" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen style={{borderRadius:"30px"}}></iframe>
            </div>
            <div className="col-lg-6">
            <h4>2. Settling in</h4>
          <p className="p-lg">Living with a new family is exciting, but at the same time, you might find a lot of things different from what you use to. Here are some tips to help you settle in:</p>
          <ul>
            <li className="p-lg">Introduce yourself and try to know your host family</li>
            <li className="p-lg">Let your host know your timetable and get to know the family’s day-to-day routine. Knowing what is going on at home, such as dinner time, shower time or curfew will reduce the misunderstanding and make you feel more comfortable at home</li>
            <li className="p-lg">Be familiar with surroundings and public transportation. Your host will show you where to catch the bus and how to get to school. Please save your homestay’s address and contact details in your phone in case you get lost</li>
          </ul>
            </div>
          </div>
          <div className="row rowmobilereverse">
            <div className="col-lg-6">
            <ul>
            <li className="p-lg">Greet your host every time you see them</li>
            <li className="p-lg">Let your host know when you are leaving home and inform them the return time</li>
            <li className="p-lg">Please discuss any medical conditions that your homestay should know about</li>
          </ul>
            </div>
            <div className="col-lg-6">
              <iframe width="100%" height="450" src="https://www.youtube.com/embed/dpbZjbAfSWY?si=_vZw0D7NB-M-GgfZ" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" referrerpolicy="strict-origin-when-cross-origin" allowfullscreen style={{borderRadius:"30px"}}></iframe>
            </div>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src={Homestay6}/>
            </div>
            <div className="col-lg-6">
              <h4>3. Meals</h4>
              <p className="p-lg"><strong>3.1 What to expect?</strong></p>
              <p className="p-lg">Breakfast in New Zealand is often a “help yourself” meal with cereal or toast and a cup of a hot drink. Your homestay will show you which food or ingredients are used for breakfast meals. You can simply prepare your own breakfast, eat, and then tidy up once you finish Lunch on weekends and public holiday is ether a “help yourself” meal or prepared by the host family.</p>
              <p className="p-lg">Dinner is the main meal of the day and you will be having dinner with your host family. If you choose no meal option, you would then need to arrange your own breakfast, lunch and dinner.</p>
            </div>
          </div>
          <div className="row">
              <p className="p-lg"><strong>3.2 What you should know about sharing meals at your homestay?</strong></p>
              <ul>
                <li className="p-lg">Please let your host know your dietary preferences. Most importantly, please let them know if you are allergic to any food so they know what food to prepare for you</li>
                <li className="p-lg">Please remember to clean up after preparing your own meals</li>
                <li className="p-lg">Most homestay hosts would like you to have meals in the dining room instead of your bedroom.</li>
              </ul>
              <p className="p-lg">This is to prevent ants and mice, or damage the carpet</p>
              <ul>
                <li className="p-lg">Please advise your host family if you will be late or away for dinner. You can either send them a text message or give them a call. Please do advise them as soon as possible</li>
                <li className="p-lg">For safety reason, please be aware that you will not be allowed to cook, unless on special occasions and under supervision..</li>
                <li className="p-lg">Please note snacks are student’s own responsibility</li>
                <li className="p-lg">Please be aware that the milk in the fridge is used for breakfast meals. You are most welcome to make a cup of tea/coffee or use for breakfast meals</li>
                <li className="p-lg">Please be aware that you should pay for yourself if your host family takes you out (movie for example) or away (e.g., overnight or weekend trip). If you prefer to have some of your own time, that is not a problem. You can simply let them know and thanks for their invitation.</li>
              </ul>
          </div>
          <div className="row">
            <div className="col-lg-6">
              <img src={Homestay7}/>
            </div>
            <div className="col-lg-6">
              <h4>4. Sharing a home with your homestay</h4>
              <p className="p-lg"><strong>4.1 Cleanliness and manners</strong></p>
              <ul>
                <li className="p-lg">Please note you are responsible for keeping your room and the bathroom you are using clean and tidy. If you have an en-suite, please make sure you clean the shower box, basin, and toilet regularly. You can ask your host parent(s) for cleaning materials and detergents. If you are sharing the bathroom with others, please take turn to clean it</li>
                <li className="p-lg">Please make sure you are using the toilet correctly in your homestay. Do not stand on toilet seat or throw the toilet paper in the bin. The used toilet paper should throw in the toilet and flush it away. Girl’s sanitary pad should be wrapped in paper and put in the bin and remember to empty the bin regularly.</li>
                <li className="p-lg">You are responsible for making your own bed each morning. Please draw the curtains to let the sunshine into your room and open the window to let in fresh air. The sunlight may warm up your room naturally but please do not keep the windows open if there is no one at home</li>
                <li className="p-lg">Contribute to household duties as other family members do, such as preparing vegetables or taking the rubbish bins out. You can ask your host to show you where the vacuum cleaner is and help you to use it</li>
                <li className="p-lg">Please remember to ask your homestay’s permission if you want to invite your friend to come over</li>
                <li className="p-lg">Please respect the property and privacy of your host family</li>
              </ul>
            </div>
          </div>

          <div className="row">
              <p className="p-lg"><strong>4.2 Laundry</strong></p>
              <ul>
                <li className="p-lg">Your homestay will make the laundry available for you to use. Please ask your host family when it is a good time to do your washing and show you how to use the washing machine. If you like to hand wash your clothes, it is not a problem. However, please do not hang the wet cloth in the bathroom or in your bedroom. You can ask your homestay if you do not know where to hang the cloth</li>
                <li className="p-lg">Your bed linen must be changed regularly. Some families do it weekly and some families do it fortnightly. You can discuss with your homestay hosts.</li>
              </ul>
          </div>

          <div className="row">
              <p className="p-lg"><strong>4.3 Electricity, internet, and water use at your homestay</strong></p>
              <ul>
                <li className="p-lg">Please be mindful in your new environment and community – respect the use of electricity, water, etc. Please turn off the heater, electric blanket and switch off lights before you leave the house</li>
                <li className="p-lg">Please note you will be charged extra if there is any excessive usage of electricity, water and internet</li>
                <li className="p-lg">Shower time in New Zealand is normally 10 minutes so please do not get offended if your homestay tells you so</li>
                <li className="p-lg">Please remember to minimise noise after 10 pm. That means you will have to take shower before 10 pm or keep your voice down if you are making a phone call/video call to your family or friends</li>
              </ul>
          </div>

          <div className="row">
              <p className="p-lg"><strong>4.4 Smoking, drinking and drugs</strong></p>
              <ul style={{width:"100%"}}>
                <li className="p-lg">Please note in New Zealand no smoking is allowed inside the house</li>
                <li className="p-lg">If you are under 18, you are not allowed to smoke or buy cigarettes at any time</li>
                <li className="p-lg">Drinking age is 18 and over in New Zealand</li>
                <li className="p-lg">You are not allowed to use illegal drugs. i.e. Marijuana, Opiates, LSD</li>
              </ul>
          </div>



          <div className="row">
            <div className="col-lg-6">
              <img src={Homestay8}/>
            </div>
            <div className="col-lg-6">
              <h4>5. Activities</h4>
              <p className="p-lg">If you have any activities arranged after school (e.g., sports, music lessons, etc) and will come back home later than the curfew time above, please email accommodation@hostfamilies.co.nz to apply for an exception permission.</p>
              <p className="p-lg">Please be sure to phone or text your homestay if you are going to be late</p>
              <p className="p-lg">Please call our 24/7 urgent assistance number at 021 026 91882.</p>
            </div>
          </div>


          <div className="row">
              <h4>6. Plan a holiday, extending homestays, or moving out</h4>
              <ul style={{width:"100%"}}>
                <li className="p-lg">If you plan to go away for a few days (over 18 students only), please make sure you notify your homestay where you are going and when you will be returning to your homestay</li>
                <li className="p-lg">If you are under the age of 18 and would like to go away for a few days, please contact us to get a consent letter. We would contact your parents or legal guardian to get a permission. Please note you are not allowed to stay in any other accommodation apart from your designated homestay before we approve your travel request.</li>
                <li className="p-lg">If you are going away for more than 6 nights, you can choose to pay a Holding fee to keep your room. However, the Holiday Plan Form must be completed and returned to HFNZ at least two weeks in advance. Otherwise, full accommodation fee will apply.</li>
                <li className="p-lg">If you plan to return to your current homestay after your holiday, although you are paying a holding fee your homestay can have another student during your absent. Therefore, you might need to pack your stuff before your travel. Your homestay will guide you where to put your luggage.</li>
                <li className="p-lg">If you plan to return to your current homestay after your holiday, although you are paying a holding fee your homestay can have another student during your absent. Therefore, you might need to pack your stuff before your travel. Your homestay will guide you where to put your luggage.</li>
                <li className="p-lg">You will need to clean your room and the bathroom you are using before moving out from your homestay. Otherwise, a cleaning fee will be charged.</li>
                <li className="p-lg">You can email us at accommodation@hostfamilies.co.nz to request the Move-out form, Holiday Plan Form or the Extension Form .</li>
              </ul>
          </div>

          <div className="row">
              <h4>7. What is not included in your homestay?</h4>
              <ul style={{width:"100%"}}>
                <li className="p-lg">Any personal expenses incurred by you during your stay including (but not limited to) toilet paper, personal hygiene products, mobile calls, excessive use of electricity/internet/water</li>
                <li className="p-lg">Provide accommodation to your family members, relatives, or friends</li>
                <li className="p-lg">Any damage to the homestay property</li>
              </ul>
          </div>

          <div className="row">
              <h4>8. Payment, cancellation, and refund policy</h4>
              <p className="p-lg">Please note that the homestay accommodation is subject to the availability of the homestay family. Most of the time, our team can find a homestay for you. However, if your requests are too specific or no families are available for your requested period, our team will inform you as soon as possible (Normally within 2 weeks after your payment is received). Once the placement is made, please note that the placement fee is non-refundable. Our team will try our best to place you with a family that meets your preferences (e.g., prefer a family with no pets or a family with young kids). However, we are unable to guarantee to meet all your requirements. This also means that we are only able to provide you with one family profile (one match). If you would like to request for a change, please note that a replacement fee of $200 will be charged to find a new family.</p>
              <p className="p-lg">Please note your placement will only be available for the original period. Any extension will be subject to the availability of the host family. If you would like to extend, you need to email us assoon as possible (at least two weeks in advance) to secure your room.If your homestay cancelled the booking prior to your arrival, Host Families NZ will try to find you a new family without charging any additional fee. However, if no family is available for your requested period, Host Families NZ will refund you the placement fee and all accommodation fees paid in advance.</p>
              <p className="p-lg">If your homestay cancelled the booking after you move in, Host Families NZ will try to find you another homestay without charging a replacement fee. However, if no family is available for there maining period of your accommodation, Host Families NZ will give you two weeks written notice to move out. That means you are responsible for finding your own accommodation.</p>
              <p className="p-lg">Please note every homestay booking requires a minimum 4 weeks stays (special bookings that are agreed in advance)All extensions must go through HFNZL. Please do not make any direct payments to your host family or negotiate the homestay rate.</p>
              <p className="p-lg">A late payment fee of $50 per month will apply where fees are not paid by the due date. If you like to cancel your booking before moving in, you are required to give a written notice to HFNZL. When the written notice of cancellation is received:</p>
              <ol>
                <li className="p-lg">8(+) Days prior to commencement of the Homestay, HFNZL will retain the Placement Fee and agrees to refund the balance of Homestay fees paid, within 14 days of receipt of the notice of cancellation</li>
                <li className="p-lg">7(-) Days prior to commencement of the Homestay, HFNZL will retain the Placement Fee, 1 week’s Homestay fee, any prepaid administrative fee, and agrees to refund the balance of Homestay fees paid, within 14 days of receipt of the notice of cancellation.</li>
                <li className="p-lg">After the commencement of the Homestay, HFNZL will retain the Placement Fee, 4 week’s Homestay fee, any prepaid administrative fee, and agrees to refund the balance of Homestay fees paid, within 14 days of receipt of the notice of cancellation.</li>
              </ol>
          </div>
          

          <div className="row">
              <h4>9. Liabilities</h4>
              <ul>
                <li className="p-lg">The student agrees to pay any damage caused by them during their stay.</li>
                <li className="p-lg">The student understands and agrees that Host Families NZ and/or the homestay family cannot be held legally responsible for any sickness, injury, damage or loss to the student or the student’s personal belongings while the student is in New Zealand.</li>
                <li className="p-lg">Host Families NZ also shall not be liable to the student in any circumstances for any consequential loss, indirect loss, loss of income, profits, or loss or damage to persons or property relating to homestay accommodation arranged by Host Families NZ .</li>
                <li className="p-lg">In the event the liability cannot be excluded, the maximum liability of Host Families NZ to the Student shall be limited to the return of the fees paid to Host Families NZ for that accommodation.</li>
              </ul>
          </div>


          <div className="row">
              <h4>10. Other Important information</h4>
              <ul>
                <li className="p-lg">Any misbehaviour of a possible criminal nature may result in suspension of your homestay while relevant authorities investigate (e.g. NZ Police)</li>
                <li className="p-lg">Please be aware that HFNZ reserves the rights to remove you from homestay without further responsibility to relocate you, if you do not follow the homestay guidelines</li>
                <li className="p-lg">Please be aware that during your homestay period, your personal belongings are at your own risk.</li>
                <li className="p-lg">Host Families NZ reserve the right to move the student out of a homestay immediately if there is any violent or safety concerns arise. In this case, the student should look for their own accommodation</li>
                <li className="p-lg">Please note that you are liable for any damage that caused by you during your stay – insurance claim or your own cost</li>
              </ul>
          </div>


          <div className="row">
              <h4>11. Host Families NZ Emergency Contact Details</h4>
              <p className="p-lg">If you need to speak to someone urgently from Host Families NZ outside of our business hours, please ring +64 21 026 91882 any time. This is a 24/7 number.</p>
          </div>

        </div>
      </div>
    </Layout>
  )
}

export default Homestay
